import React, { useRef, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import loadable from '@loadable/component'
import { StaticImage } from "gatsby-plugin-image";

import useMedia from "@hooks/useMedia";

import colors from "@styles/colors";
import text from "@styles/text";
import media from "@styles/media";
import { OpenFormContext } from "@components/Layout";

const ToolTip = loadable(() => import("@components/ToolTip"))
const TextAnimation = loadable(() => import("@components/TextAnimation"));
const PrimaryButton = loadable(() => import("@components/PrimaryButton"));

CustomEase.create("bgImg", "0.87, 0, 0.05, 1");

type props = {
  data: any
}

const Insights: React.FC<props> = ({ data }) => {
  const bgImage2Ref = useRef(null)
  const content2Ref = useRef(null);
  const wrapperRef = useRef(null);
  const toolTip1Ref = useRef(null);
  const toolTip2Ref = useRef(null);
  const toolTip3Ref = useRef(null);

  const [triggerTitle2, setTriggerTitle2] = useState(false);
  const [triggerText, setTriggerText] = useState(false);
  const [triggerToolTip1, setTriggerToolTip1] = useState(false);
  const [triggerToolTip2, setTriggerToolTip2] = useState(false);
  const [triggerToolTip3, setTriggerToolTip3] = useState(false);
  const [showToolTip1, setShowToolTip1] = useState(false);
  const [showToolTip2, setShowToolTip2] = useState(false);
  const [showToolTip3, setShowToolTip3] = useState(false);
  const [toolTipTimeline, setToolTipTimeline] = useState(null);

  const openForm = useContext(OpenFormContext);

  useEffect(() => {
      const tlScroll = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: 'top-=20% top'
        },
        onComplete: () => {
        }
      });

      tlScroll.to(bgImage2Ref.current, {
        duration: 1,
        height: "100%",
        ease: "circ.inOut",
      }, 0);
      tlScroll.call(setTriggerTitle2, [true], 0.2);
      tlScroll.call(setTriggerText, [true], 0.8);
      tlScroll.to(toolTip1Ref.current, {
        opacity: 1,
      }, 0);
      tlScroll.to(toolTip2Ref.current, {
        opacity: 1,
      }, 0);
      tlScroll.to(toolTip3Ref.current, {
        opacity: 1,
      }, 0);
      tlScroll.call(setTriggerToolTip1, [true]);
      tlScroll.call(setTriggerToolTip2, [true], 0.5);
      tlScroll.call(setTriggerToolTip3, [true], 1);


      const toolTipTl = gsap.timeline({
        repeat: -1,
        repeatDelay: 2,
      });
      toolTipTl.call(setShowToolTip1, [true], 0);
      toolTipTl.call(setShowToolTip1, [false], 2);
      toolTipTl.call(setShowToolTip2, [true], 4);
      toolTipTl.call(setShowToolTip2, [false], 6);
      toolTipTl.call(setShowToolTip3, [true], 8);
      toolTipTl.call(setShowToolTip3, [false], 10);
      setToolTipTimeline(toolTipTl);

      return () => {
        tlScroll.kill();
      };

  }, []);

  const title2TextArray = useMedia(
    [
      () => "Accurate insights",
      () => "to help your team",
      () => "optimize performance",
    ],
    [
      () => "Accurate insights",
      () => "to help your team",
      () => "optimize performance",
    ],
    [
      () => "Accurate insights",
      () => "to help your",
      () => "team optimize",
      () => "performance.",
    ],
    [
      () => "Accurate",
      () => "insights",
      () => "to help your",
      () => "team optimize",
      () => "performance.",
    ],
  )

  return (
    <Wrapper ref={wrapperRef}>
      <Bottom id="hero-bottom">
        <BackgroundImgContainer2>
          <BackgroundImg ref={bgImage2Ref}>
            <StaticImage
              src={"../../images/jpg/heroBG2-min.jpg"}
              alt="piplines 2"
              placeholder="blurred"
              objectFit="cover"
              style={{width: '100%', height: '100%'}}
            />
          </BackgroundImg>
        </BackgroundImgContainer2>
        <Content2 ref={content2Ref}>
          <TitleL>
            <TextAnimation
              textArray={title2TextArray || []}
              height={useMedia("4.167vw", "4.167vw", "5.749vw", "12.8vw")}
              className="hero-title-2"
              trigger={triggerTitle2}
            />
          </TitleL>
          <Text>
            <TextAnimation
              textArray={[
                () =>
                  "Our cutting-edge pipeline monitoring solution seamlessly reads pipelines with instant reporting. This level of detection leads to insights beyond real-time threats: by merging this constant, consistent data gathering with Artificial Intelligence and Analytics, we can predict leaks weeks, months, and even years before they occur. We maximize your information, so you can optimize operations. ",
              ]}
              height={useMedia("12.639vw", "15vw", "17.246vw", "70.667vw")}
              className="hero-text"
              trigger={triggerText}
            />
          </Text>
          <PrimaryButton
            backgroundColor={colors.keppel100}
            hoverColor={colors.keppel60}
            text="Request a Demo"
            textColor={colors.black}
            width={useMedia("fit-content", "fit-content", "fit-content", "100%")}
            onClick={() => openForm(true)}
          />
        </Content2>
        <ToolTip1 ref={toolTip1Ref}>
          <ToolTip
            parentTimeline={toolTipTimeline}
            show={showToolTip1}
            trigger={triggerToolTip1}
            title="Corrosion - Possible Leak"
            line1="7.1.21 | 6.11.47 AM"
            line2="Pipeline: 0051 | Device: 010"
            dir={useMedia("left", "left", "down", "down")}
          />
        </ToolTip1>
        <ToolTip2 ref={toolTip2Ref}>
          <ToolTip
            dir={useMedia("right", "right", "", "")}
            parentTimeline={toolTipTimeline}
            show={showToolTip2}
            trigger={triggerToolTip2}
            title="Acoustic - High Noise Level"
            line1="7.1.21 | 6.35.12 AM"
            line2="Pipeline: 0103 | Device: 003"
          />
        </ToolTip2>
        <ToolTip3 ref={toolTip3Ref}>
          <ToolTip
            dir={useMedia("right", "right", "up", "up")}
            parentTimeline={toolTipTimeline}
            show={showToolTip3}
            trigger={triggerToolTip3}
            title="GPS Movement - Land Slippage"
            line1="7.1.21 | 6.58.27 AM"
            line2="Pipeline: 0014 | Device: 102"
          />
        </ToolTip3>
      </Bottom>
    </Wrapper>
  );
};

export default Insights;

const Wrapper = styled.section`
  position: relative;
  // background-color: ${colors.black};
  width: 100vw;
`;

const Bottom = styled.div`
  position: relative;
  height: 74.861vw;

  ${media.tablet} {
    height: 101.916vw;
  }

  ${media.mobile} {
    height: 413.867vw;
  }
`

const BackgroundImgContainer2 = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 53.75vw;
  width: 50.347vw;
  right: 0;
  top: 12.639vw;

  ${media.tablet} {
    width: 54.97vw;
    height: 72.814vw;
    top: unset;
    bottom: 11.138vw;
  }

  ${media.mobile} {
    width: 100%;
    height: 186.667vw;
    top: unset;
    bottom: 0;
  }
`;

const BackgroundImg = styled.div`
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 0%;

  ${media.tablet} {
    object-position: center center;
  }
`;

const Content2 = styled.div`
  position: absolute;
  z-index: 5;
  opacity: 1;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), ${colors.black} 90%);
  box-sizing: border-box;

  width: 55.556vw;
  height: 45.069vw;
  left: 0vw;
  top: 16.597vw;
  padding-top: 7.708vw;
  padding-left: 11.458vw;
  padding-right: 2.986vw;


  ${media.tablet} {
    left: 0;
    top: 20.838vw;
    width: 56.527vw;
    height: 66.946vw;
    padding-left: 5.749vw;
    padding-right: 5.749vw;
    background-image: none;
    background-color: ${colors.black};
  }

  ${media.mobile} {
    left: 6.667vw;
    top: 32vw;
    width: 86.667vw;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    background-image: none;
    background-color: ${colors.black};
  }
`;

const TitleL = styled.h2`
  color: ${colors.culturedWhite60};
  margin: 0px;
  display: flex;
  flex-direction: column;

  ${text.desktopMHeading}
  width: 48.111vw;
  margin-bottom: 2.083vw;

  ${media.tablet} {
    ${text.tabletSHeading}
    width: 80.501vw;
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    ${text.mobileSHeading}
    width: 100%;
    margin-bottom: 8vw;
  }
`;

const Text = styled.p`
  color: ${colors.culturedWhite60};
  display: flex;

  ${text.desktopBodyCopy1}
  width: 30.694vw;
  margin-bottom: 3.125vw;

  ${media.tablet} {
    ${text.tabletSmallBody}
    width: 44.365vw;
    margin-bottom: 5vw;
  }

  ${media.mobile} {
    ${text.mobileBodyCopy1}
    width: 100%;
    margin-bottom: 5vw;
  }
`;

const toolTipStyles = `
  position: absolute;
  z-index: 5;
  opacity: 0;
`;

const ToolTip1 = styled.div`
  ${toolTipStyles}

  top: 28.889vw;
  right: 10.833vw;

  ${media.tablet} {
    top: 33.892vw;
    right: 20.12vw;
  }

  ${media.mobile} {
    top: unset;
    bottom: 130.933vw;
    right: 18.133vw;
    svg:nth-of-type(2) {
      overflow: visible;
    }
  }
`;

const ToolTip2 = styled.div`
  ${toolTipStyles}

  top: 41.25vw;
  right: 33.75vw;

  ${media.tablet} {
    display: none;
  }

  ${media.mobile} {
    display: none;
  }
`;

const ToolTip3 = styled.div`
  ${toolTipStyles}

  top: 53.194vw;
  right: 27.153vw;

  ${media.tablet} {
    top: 75.569vw;
    right: 17.725vw;
    
    svg:nth-of-type(2) {
      overflow: visible;
      rect {
        width: 32vw;
      }
    }
  }

  ${media.mobile} {
    top: unset;
    bottom: 12vw;
    right: 37.867vw;
    svg:nth-of-type(2) {
      overflow: visible;
    }
  }
`;

